.introduction{
    height: 100dvh;
    width: 100dvw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 99;
    background-color: #1a1a1a;
    cursor: none;
    svg{
        position: absolute;
        top: 0;
        width: 100%;
        height: calc(100% + 300px);
        path{
            fill: #1a1a1a;
        }
    }
    p{
        display: flex;
        color: #fff;
        font-size: 42px;
        font-family: "GeneralSans-Medium";
        letter-spacing: -0.6px;
        align-items: center;
        position: absolute;
        z-index: 1;
        img {
            margin-right: 12px;
        }
        span{
            display: block;
            width: 10px;
            height: 10px;
            background-color: #fff;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
}

@media screen and (max-width: 440px) {
    .introduction{
        p{
            font-size: 28px;
            letter-spacing: -0.6px;
            img {
                margin-right: 10px;
                width: 24px;
            }
        }
    }
} 
.hero-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .hero-container {
        width: 100%;
        height: 100%;

        // ======HERO======
        .main-hero {
            height: 80dvh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 96px;
            .main-hero-container {
                padding: 0 14rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            .cta-text-box {
                .cta-text {
                    background: #f8eee5;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 20px;
                    border: 1px solid #000;
                    padding: 4px 16px;
                    span {
                        font-family: "Satoshi";
                        font-size: 14px;
                        letter-spacing: -0.2px;
                    }
                }
            }
            .heading-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                span {
                    font-family: "GeneralSans-Semibold";
                    font-size: 48px;
                    letter-spacing: -1.8px;
                    line-height: 120%;
                    text-align: center;
                }
            }
            .subheading-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 0 2rem;
                span {
                    font-family: "GeneralSans-Medium";
                    font-size: 14px;
                    letter-spacing: -0.4px;
                    line-height: 150%;
                    text-align: center;
                }
            }
            .cta-button-box {
                a {
                    text-decoration: none;
                    button {
                        text-decoration: none;
                        outline: none;
                        background: var(--primary-100);
                        color: #fff;
                        font-size: 12px;
                        font-family: "GeneralSans";
                        letter-spacing: -0.1px;
                        padding: 8px 20px;
                        border-radius: 28px;
                    }
                }
            }
        }
        .main-hero-element:not(:last-child) {
            margin-bottom: 1.2rem;
        }

        // ======Three Cards======
        .ThreeCards-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .cards-container {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                align-items: center;
                justify-content: center;
                .card {
                    overflow: hidden;
                    border-radius: 16px;
                    width: 386px;
                    height: 380px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    border: 1px solid rgba(95, 95, 95, 0.40);
                    transition: 0.7s ease-in-out;
                    .top-card {
                        padding: 24px 30px;
                        .category {
                            span {
                                font-size: 12px;
                                text-transform: uppercase;
                                font-family: "Satoshi-Medium";
                                letter-spacing: -0.2px;
                            }
                        }
                        .card-heading {
                            span {
                                font-size: 26px;
                                font-family: "GeneralSans-Semibold";
                                letter-spacing: -0.4px;
                                line-height: 120%;
                            }
                        }
                        .card-subheading {
                            margin-top: 0.24rem;
                            line-height: 106%; /* 14.84px */
                            span {
                                font-size: 13px;
                                font-family: "GeneralSans";
                                letter-spacing: -0.2px;
                                font-style: normal;
                            }
                        }
                    }
                    // &:hover {
                    //     box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    // }
                }
                .card:not(:last-child) {
                    margin-right: 2rem;
                }
                .card1 {
                    background: url(../../assets/images/marketingCard.svg);
                    background-position: center;
                    -webkit-background-size: cover;
                    -moz-background-size: cover;
                    -o-background-size: cover;
                    background-size: cover;
                }
                .card2 {
                    background: url(../../assets/images/WebCard.svg);
                    background-position: center;
                    -webkit-background-size: cover;
                    -moz-background-size: cover;
                    -o-background-size: cover;
                    background-size: cover;
                }
                .card3 {
                    background: url(../../assets/images/brandingCard.svg);
                    background-position: center;
                    -webkit-background-size: cover;
                    -moz-background-size: cover;
                    -o-background-size: cover;
                    background-size: cover;
                }
            }
        }

        // ======Vision======
        .vision-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 96px;
            margin-bottom: 6rem;
            .vision-box {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                align-items: flex-start;
                justify-content: start;
                // padding: 10rem 17rem;
                padding-top: 12rem;
                padding-bottom: 8rem;
                .vision-heading {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    .subheading {
                        margin-bottom: 0.4rem;
                        text-align: center;
                        p {
                            font-family: "GeneralSans-Semibold";
                            letter-spacing: -0.4px;
                            font-size: 1rem;
                            color: var(--primary-100);
                        }
                    }
                    .heading {
                        text-align: center;
                        span {
                            color: var(--primary-100);
                            font-family: "Satoshi-Bold";
                            font-size: 6rem;
                            letter-spacing: -6px;
                            line-height: 90%;
                        }
                    }
                }
                .vision-description {
                    text-align: right;
                    padding: 0rem 2rem 1rem 12rem;
                    p {
                        margin: 0;
                        &:nth-of-type(1){
                            font-family: "GeneralSans-Medium";
                            letter-spacing: -0.2px;
                            font-size: 20px;
                            line-height: 120%;
                            span{
                                margin-right: 3px;
                            }
                            .mask{
                                position: relative;
                                overflow: hidden;
                                display: inline-flex;
                            }
                        }
                    }
                }
            }
        }

        // ======Testimonial======
        .herotestimonial-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-right: 2rem;
            margin-bottom: 4rem;
            .herotestimonial-container {
                position: relative;
                border-radius: 27px;
                background: #FFF;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                border-radius: 16px;
                padding: 6rem 8rem;
                width: 1080px;
                .herotestimonial-box {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    align-items: center;
                    .image-box {
                        position: relative;
                        width: 100%;
                        width: 320px;
                        height: 320px;
                        border-radius: 16px;
                        overflow: hidden;
                        img {
                            width: 100%;
                        }
                    }
                    .herotestimonial-description {
                        display: grid;
                        grid-template-rows: repeat(2, 1fr);
                        .herotestimonial {
                            span {
                                font-family: "GeneralSans-Medium";
                                letter-spacing: -0.2px;
                                font-size: 18px;
                            }
                        }
                        .test-info {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            .testimonial-name {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: flex-start;
                                .name {
                                    font-family: "Satoshi-Bold";
                                    letter-spacing: -0.2px;
                                    font-size: 18px;
                                }
                                .company-name {
                                    font-family: "Satoshi-Medium";
                                    letter-spacing: -0.2px;
                                    font-size: 16px;
                                }
                            }
                            svg {
                                height: auto;
                                width: 1.6px;
                                margin: 0 16px;
                            }
                            img {
                                width: 40px;
                            }
                        }
                    }
                }
                .hover-video {
                    position: absolute;
                    font-family: "Satoshi-Medium";
                    font-size: 12px;
                    left: 11%;
                    top: 78%;
                    color: var(--primary-100);
                }
            }
        }

        // ======Contact======
        .contacthero-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            margin-bottom: 8rem;
            padding: 0 96px;
            .contacthero-container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                .contacthero-top {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;
                    padding-bottom: 12rem;
                    border-bottom: 2px solid #363534;
                    .heading {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        .sub-sub-heading {
                            span {
                                color: #424242;
                                font-size: 20px;
                                font-family: "Satoshi-Bold";
                                letter-spacing: -0.6px;
                            }
                        }
                        .sub-heading {
                            p {
                                margin: 0;
                                &:nth-of-type(1){
                                    color: var(--primary-100);
                                    font-size: 96px;
                                    font-family: "GeneralSans-Semibold";
                                    letter-spacing: -4px;
                                    line-height: 100%;
                                    span{
                                        margin-right: 6px;
                                    }
                                    .mask{
                                        position: relative;
                                        overflow: hidden;
                                        display: inline-flex;
                                    }
                                }
                            }
                            span {
                                
                            }
                        }
                    }
                    .arrow {
                        img {
                            width: 28px;
                        }
                    }
                    .contact-magnet {
                        display: inline-block;
                        position: absolute;
                        left: calc(100% - 450px); 
                        top: calc(100% - 180px);
                        z-index: 3;
                        a {
                            text-decoration: none;
                        }
                    }
                }
                .contacthero-bottom {
                    position: relative;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    .contacthero-info {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 1em;
                        .email {
                            span {
                                font-size: 0.8rem;
                                letter-spacing: -0.4px;
                                font-family: "GeneralSans-Medium";
                            }
                        }
                        .email:not(:last-child) {
                            margin-right: 1em;
                        }
                    }
                }
            }
        }
    }
}

.hero-testimonial-heading {
    transform: translateY(20rem);
    margin-left: 96px;
    width: 100%;
    color: var(--primary-100);
    font-family: "Satoshi-Bold";
    font-size: 6rem;
    letter-spacing: -6px;
    line-height: 90%;   
}

.click-to-play {
    position: absolute;
    top: 26.2rem;
    font-size: 0.8rem;
    z-index: 2;
    opacity: 0.8;
    letter-spacing: -0.4px;
    color: #363534;
}



@media screen and (max-width: 1189px) {
    
    .hero-wrapper {
        .hero-container {
    
            // ======HERO======
            .main-hero {
                padding: 0 80px;
                .main-hero-container {
                    padding: 0 8rem;
                }
            }
            .main-hero-element:not(:last-child) {
                margin-bottom: 1rem;
            }
    
            // ======Three Cards======
            .ThreeCards-container {
                padding: 0 80px;
                .cards-container {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    align-items: center;
                    .card {
                        border-radius: 12px;
                        width: 346px;
                        height: 340px;
                    }
                    .card:not(:last-child) {
                        margin-right: 1.6rem;
                    }
                }
            }
    
            // ======Vision======
            .vision-container {
                padding: 0 80px;
                margin-bottom: 5rem;
                .vision-box {
                    padding-top: 12rem;
                    padding-bottom: 8rem;
                    .vision-heading {
                        .subheading {
                            margin-bottom: 0rem;
                            p {
                                letter-spacing: -0.4px;
                                font-size: 1rem;
                            }
                        }
                        .heading {
                            span {
                                font-size: 5rem;
                                letter-spacing: -5px;
                                line-height: 90%;
                            }
                        }
                    }
                    .vision-description {
                        padding: 0rem 0rem 1rem 12rem;
                        p {
                            margin: 0;
                            &:nth-of-type(1){
                                font-family: "GeneralSans-Medium";
                                letter-spacing: -0.2px;
                                font-size: 17px;
                                line-height: 120%;
                                span{
                                    margin-right: 2px;
                                }
                            }
                        }
                    }
                }
            }
    
            // ======Testimonial======
            .herotestimonial-wrapper {
                margin-right: 2rem;
                margin-bottom: 4rem;
                .herotestimonial-container {
                    border-radius: 16px;
                    padding: 6rem 7rem;
                    width: 1060px;
                    .herotestimonial-box {
                        .image-box {
                            width: 300px;
                            height: 300px;
                            border-radius: 14px;
                        }
                        .herotestimonial-description {
                            .herotestimonial {
                                span {
                                    letter-spacing: -0.2px;
                                    font-size: 17px;
                                }
                            }
                            .test-info {
                                .testimonial-name {
                                    .name {
                                        letter-spacing: -0.2px;
                                        font-size: 17px;
                                    }
                                    .company-name {
                                        letter-spacing: -0.2px;
                                        font-size: 15px;
                                    }
                                }
                                svg {
                                    height: auto;
                                    width: 1.5px;
                                    margin: 0 14px;
                                }
                                img {
                                    width: 36px;
                                }
                            }
                        }
                    }
                }
            }
    
            // ======Contact======
            .contacthero-box {
                padding: 0 80px;
            }
        }
    }
    
    .hero-testimonial-heading {
        transform: translateY(16rem);
        margin-left: 80px;
        font-size: 5rem;
        letter-spacing: -6px;
        line-height: 90%;   
    }
    
    .click-to-play {
        top: 31.2em;
        font-size: 0.8rem;
        z-index: 2;
        opacity: 0.8;
        letter-spacing: -0.4px;
    }
}

@media screen and (max-width: 1024px) {
    
    .hero-wrapper {
        .hero-container {
    
            // ======HERO======
            .main-hero {
                padding: 0 64px;
                .main-hero-container {
                    padding: 0 6rem;
                }
                .cta-text-box {
                    .cta-text {
                        padding: 4px 16px;
                        span {
                            font-size: 13px;
                            letter-spacing: -0.2px;
                        }
                    }
                }
                .heading-box {
                    span {
                        font-size: 42px;
                        letter-spacing: -1.8px;
                        line-height: 120%;
                    }
                }
                .subheading-box {
                    padding: 0 1rem;
                    span {
                        font-size: 12px;
                        letter-spacing: -0.4px;
                        line-height: 150%;
                    }
                }
                .cta-button-box {
                    a {
                        button {
                            font-size: 11px;
                            letter-spacing: -0.1px;
                            padding: 8px 20px;
                            border-radius: 28px;
                        }
                    }
                }
            }
            .main-hero-element:not(:last-child) {
                margin-bottom: 0.9rem;
            }
    
            // ======Three Cards======
            .ThreeCards-container {
                padding: 0 64px;
                .cards-container {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    align-items: center;
                    .card {
                        border-radius: 12px;
                        width: 306px;
                        height: 300px;
                        .top-card {
                            padding: 18px 20px;
                            .category {
                                span {
                                    font-size: 12px;
                                    text-transform: uppercase;
                                    font-family: "Satoshi-Medium";
                                    letter-spacing: -0.2px;
                                }
                            }
                            .card-heading {
                                span {
                                    font-size: 24px;
                                    font-family: "GeneralSans-Semibold";
                                    letter-spacing: -0.4px;
                                    line-height: 120%;
                                }
                            }
                            .card-subheading {
                                margin-top: 0.24rem;
                                line-height: 106%; /* 14.84px */
                                span {
                                    font-size: 12px;
                                    font-family: "GeneralSans";
                                    letter-spacing: -0.2px;
                                    font-style: normal;
                                    line-height: 100%;
                                }
                            }
                        }
                    }
                    .card:not(:last-child) {
                        margin-right: 1rem;
                    }
                }
            }
    
            // ======Vision======
            .vision-container {
                padding: 0 64px;
                margin-bottom: 5rem;
                .vision-box {
                    padding-top: 12rem;
                    padding-bottom: 8rem;
                    .vision-heading {
                        .subheading {
                            margin-bottom: 0rem;
                            p {
                                letter-spacing: -0.4px;
                                font-size: 1rem;
                            }
                        }
                        .heading {
                            span {
                                font-size: 5rem;
                                letter-spacing: -5px;
                                line-height: 90%;
                            }
                        }
                    }
                    .vision-description {
                        padding: 0rem 0rem 1rem 8rem;
                        p {
                            margin: 0;
                            &:nth-of-type(1){
                                font-family: "GeneralSans-Medium";
                                letter-spacing: -0.2px;
                                font-size: 17px;
                                line-height: 120%;
                                span{
                                    margin-right: 2px;
                                }
                            }
                        }
                    }
                }
            }
    
            // ======Testimonial======
            .herotestimonial-wrapper {
                margin-right: 2rem;
                margin-bottom: 4rem;
                .herotestimonial-container {
                    border-radius: 16px;
                    padding: 6rem 6rem;
                    width: 960px;
                    .herotestimonial-box {
                        .image-box {
                            width: 280px;
                            height: 280px;
                            border-radius: 16px;
                        }
                        .herotestimonial-description {
                            .herotestimonial {
                                span {
                                    letter-spacing: -0.2px;
                                    font-size: 16px;
                                }
                            }
                            .test-info {
                                .testimonial-name {
                                    .name {
                                        letter-spacing: -0.2px;
                                        font-size: 16px;
                                    }
                                    .company-name {
                                        letter-spacing: -0.2px;
                                        font-size: 14px;
                                    }
                                }
                                svg {
                                    height: auto;
                                    width: 1.4px;
                                    margin: 0 14px;
                                }
                                img {
                                    width: 28px;
                                }
                            }
                        }
                    }
                }
            }
    
            // ======Contact======
            .contacthero-box {
                padding: 0 64px;
            }
        }
    }
    
    .hero-testimonial-heading {
        transform: translateY(16rem);
        margin-left: 64px;
        font-size: 4.8rem;
        letter-spacing: -6px;
        line-height: 90%;   
    }
    
    .click-to-play {
        top: 34em;
        font-size: 0.7rem;
        z-index: 2;
        opacity: 0.8;
        letter-spacing: -0.4px;
    }
}

@media screen and (max-width: 825px) {
    
    .hero-wrapper {
        .hero-container {
    
            // ======HERO======
            .main-hero {
                padding: 0 56px;
                .main-hero-container {
                    padding: 0 6rem;
                }
                .cta-text-box {
                    .cta-text {
                        padding: 4px 16px;
                        span {
                            font-size: 13px;
                            letter-spacing: -0.2px;
                        }
                    }
                }
                .heading-box {
                    span {
                        font-size: 42px;
                        letter-spacing: -1.8px;
                        line-height: 120%;
                    }
                }
                .subheading-box {
                    padding: 0 1rem;
                    span {
                        font-size: 12px;
                        letter-spacing: -0.4px;
                        line-height: 150%;
                    }
                }
                .cta-button-box {
                    a {
                        button {
                            font-size: 11px;
                            letter-spacing: -0.1px;
                            padding: 8px 20px;
                            border-radius: 28px;
                        }
                    }
                }
            }
            .main-hero-element:not(:last-child) {
                margin-bottom: 0.9rem;
            }
    
            // ======Three Cards======
            .ThreeCards-container {
                padding: 0 56px;
                .cards-container {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-auto-rows: auto;
                    align-items: center;
                    .card {
                        border-radius: 12px;
                        width: 326px;
                        height: 320px;
                        margin-bottom: 1.5rem;
                        .top-card {
                            padding: 18px 20px;
                            .category {
                                span {
                                    font-size: 12px;
                                    text-transform: uppercase;
                                    font-family: "Satoshi-Medium";
                                    letter-spacing: -0.2px;
                                }
                            }
                            .card-heading {
                                span {
                                    font-size: 24px;
                                    font-family: "GeneralSans-Semibold";
                                    letter-spacing: -0.4px;
                                    line-height: 120%;
                                }
                            }
                            .card-subheading {
                                margin-top: 0.24rem;
                                line-height: 106%; /* 14.84px */
                                span {
                                    font-size: 12px;
                                    font-family: "GeneralSans";
                                    letter-spacing: -0.2px;
                                    font-style: normal;
                                    line-height: 100%;
                                }
                            }
                        }
                    }
                    .card3 {
                        grid-column: 1 / span 2; /* Span the entire width of the grid */
  justify-self: center;
                    }
                    .card:not(:last-child) {
                        margin-right: 1.5rem;
                    }
                }
            }
    
            // ======Vision======
            .vision-container {
                padding: 0 56px;
                margin-bottom: 2rem;
                .vision-box {
                    padding-top: 8rem;
                    padding-bottom: 8rem;
                    display: block;
                    .vision-heading {
                        margin-bottom: 1rem;
                        .subheading {
                            margin-bottom: 0rem;
                            p {
                                letter-spacing: -0.4px;
                                font-size: 0.95rem;
                            }
                        }
                        .heading {
                            span {
                                font-size: 4.8rem;
                                letter-spacing: -5px;
                                line-height: 90%;
                            }
                        }
                    }
                    .vision-description {
                        padding: 2rem 0rem 1rem 24rem;
                        p {
                            margin: 0;
                            &:nth-of-type(1){
                                font-family: "GeneralSans-Medium";
                                letter-spacing: -0.2px;
                                font-size: 16px;
                                line-height: 120%;
                                span{
                                    margin-right: 1.8px;
                                }
                            }
                        }
                    }
                }
            }
    
            // ======Testimonial======
            .herotestimonial-wrapper {
                margin-right: 2rem;
                margin-bottom: 4rem;
                .herotestimonial-container {
                    border-radius: 16px;
                    padding: 6rem 6rem;
                    width: 960px;
                    .herotestimonial-box {
                        .image-box {
                            width: 280px;
                            height: 280px;
                            border-radius: 16px;
                        }
                        .herotestimonial-description {
                            .herotestimonial {
                                span {
                                    letter-spacing: -0.2px;
                                    font-size: 16px;
                                }
                            }
                            .test-info {
                                .testimonial-name {
                                    .name {
                                        letter-spacing: -0.2px;
                                        font-size: 16px;
                                    }
                                    .company-name {
                                        letter-spacing: -0.2px;
                                        font-size: 14px;
                                    }
                                }
                                svg {
                                    height: auto;
                                    width: 1.4px;
                                    margin: 0 14px;
                                }
                                img {
                                    width: 28px;
                                }
                            }
                        }
                    }
                }
            }
    
            // ======Contact======
            .contacthero-box {
                padding: 0 56px;
                .contacthero-container {
                    .contacthero-top {
                        .contact-magnet {
                            display: inline-block;
                            position: absolute;
                            left: calc(100% - 336px); 
                            top: calc(100% - 180px);
                            z-index: 3;
                            a {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .hero-testimonial-heading {
        transform: translateY(16rem);
        margin-left: 56px;
        font-size: 4.8rem;
        letter-spacing: -6px;
        line-height: 90%;   
    }
    
    .click-to-play {
        top: 34em;
        font-size: 0.7rem;
        z-index: 2;
        opacity: 0.8;
        letter-spacing: -0.4px;
    }
}

@media screen and (max-width: 769px) {
    
    .hero-wrapper {
        .hero-container {
    
            // ======HERO======
            .main-hero {
                padding: 0 48px;
                .main-hero-container {
                    padding: 0 1rem;
                }
                .cta-text-box {
                    .cta-text {
                        padding: 4px 16px;
                        span {
                            font-size: 12px;
                            letter-spacing: -0.2px;
                        }
                    }
                }
                .heading-box {
                    span {
                        font-size: 38px;
                        letter-spacing: -1.8px;
                        line-height: 120%;
                    }
                }
                .subheading-box {
                    padding: 0 1rem;
                    span {
                        font-size: 12px;
                        letter-spacing: -0.4px;
                        line-height: 150%;
                    }
                }
                .cta-button-box {
                    a {
                        button {
                            font-size: 10px;
                            letter-spacing: -0.1px;
                            padding: 8px 20px;
                            border-radius: 28px;
                        }
                    }
                }
            }
            .main-hero-element:not(:last-child) {
                margin-bottom: 0.9rem;
            }
    
            // ======Three Cards======
            .ThreeCards-container {
                padding: 0 48px;
                .cards-container {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-auto-rows: auto;
                    align-items: center;
                    column-gap: 1.4rem;
                    .card {
                        border-radius: 12px;
                        width: 326px;
                        height: 320px;
                        margin-bottom: 1.5rem;
                        .top-card {
                            padding: 18px 20px;
                            .category {
                                span {
                                    font-size: 12px;
                                    text-transform: uppercase;
                                    font-family: "Satoshi-Medium";
                                    letter-spacing: -0.2px;
                                }
                            }
                            .card-heading {
                                span {
                                    font-size: 24px;
                                    font-family: "GeneralSans-Semibold";
                                    letter-spacing: -0.4px;
                                    line-height: 120%;
                                }
                            }
                            .card-subheading {
                                margin-top: 0.24rem;
                                line-height: 106%; /* 14.84px */
                                span {
                                    font-size: 12px;
                                    font-family: "GeneralSans";
                                    letter-spacing: -0.2px;
                                    font-style: normal;
                                    line-height: 100%;
                                }
                            }
                        }
                    }
                    .card3 {
                        grid-column: 1 / span 2; /* Span the entire width of the grid */
                        justify-self: center;
                    }
                    .card:not(:last-child) {
                        margin-right: 0rem;
                    }
                }
            }
    
            // ======Vision======
            .vision-container {
                padding: 0 48px;
                margin-bottom: 2rem;
                .vision-box {
                    padding-top: 8rem;
                    padding-bottom: 8rem;
                    display: block;
                    .vision-heading {
                        margin-bottom: 1rem;
                        .subheading {
                            margin-bottom: 0rem;
                            p {
                                letter-spacing: -0.4px;
                                font-size: 0.95rem;
                            }
                        }
                        .heading {
                            span {
                                font-size: 4.8rem;
                                letter-spacing: -5px;
                                line-height: 90%;
                            }
                        }
                    }
                    .vision-description {
                        padding: 2rem 0rem 1rem 20rem;
                        p {
                            margin: 0;
                            &:nth-of-type(1){
                                font-family: "GeneralSans-Medium";
                                letter-spacing: -0.2px;
                                font-size: 16px;
                                line-height: 120%;
                                span{
                                    margin-right: 1.8px;
                                }
                            }
                        }
                    }
                }
            }
    
            // ======Testimonial======
            .herotestimonial-wrapper {
                margin-right: 2rem;
                margin-bottom: 4rem;
                .herotestimonial-container {
                    border-radius: 16px;
                    padding: 4rem 5rem;
                    width: 480px;
                    height: 80dvh;
                    .herotestimonial-box {
                        display: grid;
                        grid-template-columns: none;
                        grid-template-rows: none;
                        .image-box {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            width: 100%;
                            height: auto;
                            border-radius: 16px;
                            video {
                                width: 320px;
                                height: 320px;
                            }
                            img {
                                width: 320px;
                                height: 320px;
                            }
                        }
                        .herotestimonial-description {
                            margin-top: 2.4rem;
                            .herotestimonial {
                                span {
                                    display: block;
                                    line-height: 1.5;
                                    letter-spacing: -0.2px;
                                    font-size: 14px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 4; /* number of lines to show */
                                            line-clamp: 4;
                                    -webkit-box-orient: vertical;
                                }
                            }
                            .test-info {
                                .testimonial-name {
                                    .name {
                                        letter-spacing: -0.2px;
                                        font-size: 14px;
                                    }
                                    .company-name {
                                        letter-spacing: -0.2px;
                                        font-size: 12px;
                                    }
                                }
                                svg {
                                    height: auto;
                                    width: 1.2px;
                                    margin: 0 12px;
                                }
                                img {
                                    width: 24px;
                                }
                            }
                        }
                    }
                }
            }
    
            // ======Contact======
            .contacthero-box {
                padding: 0 48px;
                margin-bottom: 8rem;
                .contacthero-container {
                    .contacthero-top {
                        padding-bottom: 10rem;
                        border-bottom: 2px solid #363534;
                        .heading {
                            .sub-sub-heading {
                                span {
                                    font-size: 16px;
                                    letter-spacing: -0.6px;
                                }
                            }
                            .sub-heading {
                                p {
                                    margin: 0;
                                    &:nth-of-type(1){
                                        color: var(--primary-100);
                                        font-size: 72px;
                                        font-family: "GeneralSans-Semibold";
                                        letter-spacing: -4px;
                                        line-height: 100%;
                                        span{
                                            margin-right: 6px;
                                        }
                                        .mask{
                                            position: relative;
                                            overflow: hidden;
                                            display: inline-flex;
                                        }
                                    }
                                }
                                span {
                                    
                                }
                            }
                        }
                        .arrow {
                            img {
                                width: 20px;
                            }
                        }
                        .contact-magnet {
                            display: inline-block;
                            position: absolute;
                            left: calc(100% - 336px); 
                            top: calc(100% - 180px);
                            z-index: 3;
                            a {
                                text-decoration: none;
                            }
                        }
                    }
                    .contacthero-bottom {
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        .contacthero-info {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 1em;
                            .email {
                                span {
                                    font-size: 0.7rem;
                                    letter-spacing: -0.4px;
                                    font-family: "GeneralSans-Medium";
                                }
                            }
                            .email:not(:last-child) {
                                margin-right: 1em;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .hero-testimonial-heading {
        transform: translateY(16rem);
        margin-left: 56px;
        font-size: 4.8rem;
        letter-spacing: -6px;
        line-height: 90%;   
    }
    
    .click-to-play {
        top: 32.5em;
        left: 8em;
        font-size: 0.7rem;
        z-index: 2;
        opacity: 0.8;
        letter-spacing: -0.4px;
    }
}

@media screen and (max-width: 440px) {
    
    .hero-wrapper {
        .hero-container {
    
            // ======HERO======
            .main-hero {
                padding: 0 32px;
                .main-hero-container {
                    padding: 0 1rem;
                }
                .cta-text-box {
                    .cta-text {
                        padding: 4px 16px;
                        span {
                            font-size: 12px;
                            letter-spacing: -0.2px;
                        }
                    }
                }
                .heading-box {
                    span {
                        font-size: 32px;
                        letter-spacing: -1.8px;
                        line-height: 120%;
                    }
                }
                .subheading-box {
                    padding: 0 1rem;
                    span {
                        font-size: 12px;
                        letter-spacing: -0.4px;
                        line-height: 150%;
                    }
                }
                .cta-button-box {
                    margin-top: 0.2rem;
                    a {
                        button {
                            font-size: 10px;
                            letter-spacing: -0.1px;
                            padding: 8px 20px;
                            border-radius: 28px;
                        }
                    }
                }
            }
            .main-hero-element:not(:last-child) {
                margin-bottom: 0.6rem;
            }
    
            // ======Three Cards======
            .ThreeCards-container {
                padding: 0 48px;
                .cards-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    column-gap: 1.4rem;
                    .card {
                        border-radius: 12px;
                        width: 326px;
                        height: 320px;
                        margin-bottom: 1.5rem;
                        .top-card {
                            padding: 18px 20px;
                            .category {
                                span {
                                    font-size: 12px;
                                    text-transform: uppercase;
                                    font-family: "Satoshi-Medium";
                                    letter-spacing: -0.2px;
                                }
                            }
                            .card-heading {
                                span {
                                    font-size: 24px;
                                    font-family: "GeneralSans-Semibold";
                                    letter-spacing: -0.4px;
                                    line-height: 120%;
                                }
                            }
                            .card-subheading {
                                margin-top: 0.24rem;
                                line-height: 106%; /* 14.84px */
                                span {
                                    font-size: 12px;
                                    font-family: "GeneralSans";
                                    letter-spacing: -0.2px;
                                    font-style: normal;
                                    line-height: 100%;
                                }
                            }
                        }
                    }
                    .card3 {
                        grid-column: 1 / span 2; /* Span the entire width of the grid */
                        justify-self: center;
                    }
                    .card:not(:last-child) {
                        margin-right: 0rem;
                    }
                }
            }
    
            // ======Vision======
            .vision-container {
                padding: 0 32px;
                margin-bottom: 0;
                .vision-box {
                    padding-top: 6rem;
                    padding-bottom: 6rem;
                    display: block;
                    .vision-heading {
                        margin-bottom: 1rem;
                        .subheading {
                            margin-bottom: 0rem;
                            p {
                                letter-spacing: -0.4px;
                                font-size: 0.8rem;
                            }
                        }
                        .heading {
                            span {
                                font-size: 3.8rem;
                                letter-spacing: -3.6px;
                                line-height: 90%;
                            }
                        }
                    }
                    .vision-description {
                        padding: 1.6rem 0rem 1rem 8rem;
                        p {
                            margin: 0;
                            &:nth-of-type(1){
                                font-family: "GeneralSans-Medium";
                                letter-spacing: -0.2px;
                                font-size: 12px;
                                line-height: 120%;
                                span{
                                    margin-right: 1.8px;
                                }
                            }
                        }
                    }
                }
            }
    
            // ======Testimonial======
            .herotestimonial-wrapper {
                margin-right: 2rem;
                margin-bottom: 4rem;
                .herotestimonial-container {
                    border-radius: 16px;
                    padding: 4.4rem 4rem;
                    width: 380px;
                    height: auto;
                    .herotestimonial-box {
                        display: grid;
                        grid-template-columns: none;
                        grid-template-rows: none;
                        .image-box {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            width: 100%;
                            height: auto;
                            border-radius: 16px;
                            video {
                                width: 252px;
                                height: 252px;
                            }
                            img {
                                width: 252px;
                                height: 252px;
                            }
                        }
                        .herotestimonial-description {
                            margin-top: 2.4rem;
                            .herotestimonial {
                                span {
                                    display: block;
                                    line-height: 1.5;
                                    letter-spacing: -0.2px;
                                    font-size: 12px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 5; /* number of lines to show */
                                            line-clamp: 5;
                                    -webkit-box-orient: vertical;
                                }
                            }
                            .test-info {
                                .testimonial-name {
                                    .name {
                                        letter-spacing: -0.2px;
                                        font-size: 13px;
                                    }
                                    .company-name {
                                        letter-spacing: -0.2px;
                                        font-size: 11px;
                                    }
                                }
                                svg {
                                    height: auto;
                                    width: 1.2px;
                                    margin: 0 12px;
                                }
                                img {
                                    width: 24px;
                                }
                            }
                        }
                    }
                }
            }
    
            // ======Contact======
            .contacthero-box {
                padding: 0 32px;
                margin-bottom: 4rem;
                .contacthero-container {
                    .contacthero-top {
                        padding-bottom: 16rem;
                        border-bottom: 1.4px solid #363534;
                        .heading {
                            .sub-sub-heading {
                                span {
                                    font-size: 16px;
                                    letter-spacing: -0.6px;
                                }
                            }
                            .sub-heading {
                                p {
                                    margin: 0;
                                    &:nth-of-type(1){
                                        color: var(--primary-100);
                                        font-size: 72px;
                                        font-family: "GeneralSans-Semibold";
                                        letter-spacing: -4px;
                                        line-height: 100%;
                                        span{
                                            margin-right: 6px;
                                        }
                                        .mask{
                                            position: relative;
                                            overflow: hidden;
                                            display: inline-flex;
                                        }
                                    }
                                }
                                span {
                                    
                                }
                            }
                        }
                        .arrow {
                            img {
                                width: 20px;
                            }
                        }
                        .contact-magnet {
                            display: inline-block;
                            position: absolute;
                            left: calc(100% - 260px); 
                            top: calc(100% - 300px);
                            z-index: 3;
                            a {
                                text-decoration: none;
                            }
                        }
                    }
                    .contacthero-bottom {
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        .contacthero-info {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 1em;
                            .email {
                                span {
                                    font-size: 0.64rem;
                                    letter-spacing: -0.4px;
                                    font-family: "GeneralSans-Medium";
                                }
                            }
                            .email:not(:last-child) {
                                margin-right: 0.4em;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .hero-testimonial-heading {
        transform: translateY(16rem);
        margin-left: 32px;
        font-size: 3.8rem;
        letter-spacing: -3.6px;
        line-height: 90%;   
    }
    
    .click-to-play {
        color: #fff;
        top: 31.5em;
        left: 7.5em;
        font-size: 0.6rem;
        z-index: 2;
        opacity: 0.8;
        letter-spacing: -0.4px;
    }
}

@media screen and (max-width: 414px) {
    
    .hero-wrapper {
        .hero-container {
    
            // ======HERO======
            .main-hero {
                padding: 0 32px;
                .main-hero-container {
                    padding: 0 0.4rem;
                }
                .cta-text-box {
                    .cta-text {
                        padding: 4px 16px;
                        span {
                            font-size: 10px;
                            letter-spacing: -0.2px;
                        }
                    }
                }
                .heading-box {
                    span {
                        font-size: 28px;
                        letter-spacing: -1.8px;
                        line-height: 120%;
                    }
                }
                .subheading-box {
                    padding: 0 0.4rem;
                    span {
                        font-size: 12px;
                        letter-spacing: -0.4px;
                        line-height: 126%;
                    }
                }
                .cta-button-box {
                    margin-top: 0.2rem;
                    a {
                        button {
                            font-size: 9px;
                            letter-spacing: -0.1px;
                            padding: 8px 20px;
                            border-radius: 28px;
                        }
                    }
                }
            }
            .main-hero-element:not(:last-child) {
                margin-bottom: 0.8rem;
            }
    
            // ======Three Cards======
            .ThreeCards-container {
                padding: 0 32px;
                .cards-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    column-gap: 1.4rem;
                    .card {
                        border-radius: 12px;
                        width: 326px;
                        height: 320px;
                        margin-bottom: 1.5rem;
                        .top-card {
                            padding: 18px 20px;
                            .category {
                                span {
                                    font-size: 12px;
                                    text-transform: uppercase;
                                    font-family: "Satoshi-Medium";
                                    letter-spacing: -0.2px;
                                }
                            }
                            .card-heading {
                                span {
                                    font-size: 24px;
                                    font-family: "GeneralSans-Semibold";
                                    letter-spacing: -0.4px;
                                    line-height: 120%;
                                }
                            }
                            .card-subheading {
                                margin-top: 0.24rem;
                                line-height: 106%; /* 14.84px */
                                span {
                                    font-size: 12px;
                                    font-family: "GeneralSans";
                                    letter-spacing: -0.2px;
                                    font-style: normal;
                                    line-height: 100%;
                                }
                            }
                        }
                    }
                    .card3 {
                        grid-column: 1 / span 2; /* Span the entire width of the grid */
                        justify-self: center;
                    }
                    .card:not(:last-child) {
                        margin-right: 0rem;
                    }
                }
            }
    
            // ======Testimonial======
            .herotestimonial-wrapper {
                .herotestimonial-container {
                    border-radius: 16px;
                    padding: 4.4rem 4rem;
                    width: 360px;
                    height: auto;
                    .herotestimonial-box {
                        .image-box {
                            border-radius: 16px;
                            video {
                                width: 232px;
                                height: 232px;
                            }
                            img {
                                width: 232px;
                                height: 232px;
                            }
                        }
                        .herotestimonial-description {
                            margin-top: 2.4rem;
                            .herotestimonial {
                                span {
                                    display: block;
                                    line-height: 1.5;
                                    letter-spacing: -0.2px;
                                    font-size: 12px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 5; /* number of lines to show */
                                            line-clamp: 5;
                                    -webkit-box-orient: vertical;
                                }
                            }
                            .test-info {
                                .testimonial-name {
                                    .name {
                                        letter-spacing: -0.2px;
                                        font-size: 13px;
                                    }
                                    .company-name {
                                        letter-spacing: -0.2px;
                                        font-size: 11px;
                                    }
                                }
                                svg {
                                    height: auto;
                                    width: 1.2px;
                                    margin: 0 12px;
                                }
                                img {
                                    width: 24px;
                                }
                            }
                        }
                    }
                }
            }
    
            // ======Contact======
            .contacthero-box {
                padding: 0 32px;
                margin-bottom: 4rem;
                .contacthero-container {
                    .contacthero-top {
                        padding-bottom: 16rem;
                        border-bottom: 1.4px solid #363534;
                        .heading {
                            .sub-sub-heading {
                                span {
                                    font-size: 16px;
                                    letter-spacing: -0.6px;
                                }
                            }
                            .sub-heading {
                                p {
                                    margin: 0;
                                    &:nth-of-type(1){
                                        color: var(--primary-100);
                                        font-size: 72px;
                                        font-family: "GeneralSans-Semibold";
                                        letter-spacing: -4px;
                                        line-height: 100%;
                                        span{
                                            margin-right: 6px;
                                        }
                                        .mask{
                                            position: relative;
                                            overflow: hidden;
                                            display: inline-flex;
                                        }
                                    }
                                }
                                span {
                                    
                                }
                            }
                        }
                        .arrow {
                            img {
                                width: 20px;
                            }
                        }
                        .contact-magnet {
                            display: inline-block;
                            position: absolute;
                            left: calc(100% - 260px); 
                            top: calc(100% - 300px);
                            z-index: 3;
                            a {
                                text-decoration: none;
                            }
                        }
                    }
                    .contacthero-bottom {
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        .contacthero-info {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 1em;
                            .email {
                                span {
                                    font-size: 0.64rem;
                                    letter-spacing: -0.4px;
                                    font-family: "GeneralSans-Medium";
                                }
                            }
                            .email:not(:last-child) {
                                margin-right: 0.4em;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .hero-testimonial-heading {
        transform: translateY(16rem);
        margin-left: 32px;
        font-size: 3.8rem;
        letter-spacing: -3.6px;
        line-height: 90%;   
    }
    
    .click-to-play {
        color: #fff;
        top: 29.5em;
        left: 7.5em;
        font-size: 0.6rem;
        z-index: 2;
        opacity: 0.8;
        letter-spacing: -0.4px;
    }
}

@media screen and (max-width: 400px) {
    
    .hero-wrapper {
        .hero-container {
            
            // ======Testimonial======
            .herotestimonial-wrapper {
                margin-right: 2rem;
                margin-bottom: 4rem;
                .herotestimonial-container {
                    border-radius: 16px;
                    padding: 3.4rem 3rem;
                    width: 336px;
                    height: auto;
                    .herotestimonial-box {
                        display: grid;
                        grid-template-columns: none;
                        grid-template-rows: none;
                        .image-box {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            width: 100%;
                            height: auto;
                            border-radius: 14px;
                            video {
                                width: 240px;
                                height: 240px;
                            }
                            img {
                                width: 240px;
                                height: 240px;
                            }
                        }
                        .herotestimonial-description {
                            margin-top: 2.4rem;
                            .herotestimonial {
                                span {
                                    display: block;
                                    line-height: 1.5;
                                    letter-spacing: -0.2px;
                                    font-size: 12px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 5; /* number of lines to show */
                                    line-clamp: 5;
                                    -webkit-box-orient: vertical;
                                }
                            }
                            .test-info {
                                .testimonial-name {
                                    .name {
                                        letter-spacing: -0.2px;
                                        font-size: 13px;
                                    }
                                    .company-name {
                                        letter-spacing: -0.2px;
                                        font-size: 11px;
                                    }
                                }
                                svg {
                                    height: auto;
                                    width: 1.2px;
                                    margin: 0 12px;
                                }
                                img {
                                    width: 24px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .click-to-play {
        color: #fff;
        top: 28.5em;
        left: 6.5em;
        font-size: 0.6rem;
        z-index: 2;
        opacity: 0.8;
        letter-spacing: -0.4px;
    }
}

@media screen and (max-width: 375px) {
    
    .hero-wrapper {
        .hero-container {
    
            // ======HERO======
            .main-hero {
                padding: 0 18px;
            }
    
            // ======Three Cards======
            .ThreeCards-container {
                padding: 0 28px;
                .cards-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    column-gap: 1.4rem;
                    .card {
                        border-radius: 12px;
                        width: 326px;
                        height: 320px;
                        margin-bottom: 1.5rem;
                        .top-card {
                            padding: 18px 20px;
                            .category {
                                span {
                                    font-size: 12px;
                                    text-transform: uppercase;
                                    font-family: "Satoshi-Medium";
                                    letter-spacing: -0.2px;
                                }
                            }
                            .card-heading {
                                span {
                                    font-size: 24px;
                                    font-family: "GeneralSans-Semibold";
                                    letter-spacing: -0.4px;
                                    line-height: 120%;
                                }
                            }
                            .card-subheading {
                                margin-top: 0.24rem;
                                line-height: 106%; /* 14.84px */
                                span {
                                    font-size: 12px;
                                    font-family: "GeneralSans";
                                    letter-spacing: -0.2px;
                                    font-style: normal;
                                    line-height: 100%;
                                }
                            }
                        }
                    }
                    .card3 {
                        grid-column: 1 / span 2; /* Span the entire width of the grid */
                        justify-self: center;
                    }
                    .card:not(:last-child) {
                        margin-right: 0rem;
                    }
                }
            }
    
            // ======Testimonial======
            .herotestimonial-wrapper {
                margin-right: 2rem;
                margin-bottom: 4rem;
                .herotestimonial-container {
                    border-radius: 16px;
                    padding: 3.4rem 3rem;
                    width: 320px;
                    height: auto;
                    .herotestimonial-box {
                        display: grid;
                        grid-template-columns: none;
                        grid-template-rows: none;
                        .image-box {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            width: 100%;
                            height: auto;
                            border-radius: 14px;
                            video {
                                width: 224px;
                                height: 224px;
                            }
                            img {
                                width: 224px;
                                height: 224px;
                            }
                        }
                        .herotestimonial-description {
                            margin-top: 2.4rem;
                            .herotestimonial {
                                span {
                                    display: block;
                                    line-height: 1.5;
                                    letter-spacing: -0.2px;
                                    font-size: 12px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 5; /* number of lines to show */
                                    line-clamp: 5;
                                    -webkit-box-orient: vertical;
                                }
                            }
                            .test-info {
                                .testimonial-name {
                                    .name {
                                        letter-spacing: -0.2px;
                                        font-size: 13px;
                                    }
                                    .company-name {
                                        letter-spacing: -0.2px;
                                        font-size: 11px;
                                    }
                                }
                                svg {
                                    height: auto;
                                    width: 1.2px;
                                    margin: 0 12px;
                                }
                                img {
                                    width: 24px;
                                }
                            }
                        }
                    }
                }
            }
    
            // ======Contact======
            .contacthero-box {
                padding: 0 32px;
                margin-bottom: 4rem;
                .contacthero-container {
                    .contacthero-top {
                        padding-bottom: 16rem;
                        .contact-magnet {
                            display: inline-block;
                            position: absolute;
                            left: calc(100% - 260px); 
                            top: calc(100% - 292px);
                            z-index: 3;
                            a {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .hero-testimonial-heading {
        transform: translateY(16rem);
        margin-left: 32px;
        font-size: 3.8rem;
        letter-spacing: -3.6px;
        line-height: 90%;   
    }
    
    .click-to-play {
        color: #fff;
        top: 32.5em;
        left: 7.5em;
        font-size: 0.5rem;
        z-index: 2;
        opacity: 0.8;
        letter-spacing: -0.4px;
    }
}

@media screen and (max-width: 360px) {
    
    .hero-wrapper {
        .hero-container {
    
            // ======HERO======
            .main-hero {
                padding: 0 16px;
                .main-hero-container {
                    padding: 0 0.4rem;
                }
                .cta-text-box {
                    .cta-text {
                        padding: 4px 16px;
                        span {
                            font-size: 10px;
                            letter-spacing: -0.2px;
                        }
                    }
                }
                .heading-box {
                    span {
                        font-size: 28px;
                        letter-spacing: -1.8px;
                        line-height: 120%;
                    }
                }
                .subheading-box {
                    padding: 0 0.4rem;
                    span {
                        font-size: 12px;
                        letter-spacing: -0.4px;
                        line-height: 126%;
                    }
                }
                .cta-button-box {
                    margin-top: 0.2rem;
                    a {
                        button {
                            font-size: 9px;
                            letter-spacing: -0.1px;
                            padding: 8px 20px;
                            border-radius: 28px;
                        }
                    }
                }
            }
            .main-hero-element:not(:last-child) {
                margin-bottom: 0.8rem;
            }
    
            // ======Three Cards======
            .ThreeCards-container {
                padding: 0 28px;
                .cards-container {
                    .card {
                        border-radius: 12px;
                        width: 326px;
                        height: 320px;
                        margin-bottom: 1.5rem;
                        .top-card {
                            padding: 18px 20px;
                            .category {
                                span {
                                    font-size: 12px;
                                    text-transform: uppercase;
                                    font-family: "Satoshi-Medium";
                                    letter-spacing: -0.2px;
                                }
                            }
                            .card-heading {
                                span {
                                    font-size: 24px;
                                    font-family: "GeneralSans-Semibold";
                                    letter-spacing: -0.4px;
                                    line-height: 120%;
                                }
                            }
                            .card-subheading {
                                margin-top: 0.24rem;
                                line-height: 106%; /* 14.84px */
                                span {
                                    font-size: 12px;
                                    font-family: "GeneralSans";
                                    letter-spacing: -0.2px;
                                    font-style: normal;
                                    line-height: 100%;
                                }
                            }
                        }
                    }
                    .card3 {
                        grid-column: 1 / span 2; /* Span the entire width of the grid */
                        justify-self: center;
                    }
                    .card:not(:last-child) {
                        margin-right: 0rem;
                    }
                }
            }
    
            // ======Vision======
            .vision-container {
                padding: 0 18px;
                margin-bottom: 0rem;
                .vision-box {
                    padding-top: 6rem;
                    padding-bottom: 6rem;
                    display: block;
                    .vision-heading {
                        margin-bottom: 1rem;
                        .subheading {
                            margin-bottom: 0rem;
                            p {
                                letter-spacing: -0.4px;
                                font-size: 0.8rem;
                            }
                        }
                        .heading {
                            span {
                                font-size: 3.8rem;
                                letter-spacing: -3.6px;
                                line-height: 90%;
                            }
                        }
                    }
                    .vision-description {
                        padding: 1.6rem 0rem 1rem 8rem;
                        p {
                            margin: 0;
                            &:nth-of-type(1){
                                font-family: "GeneralSans-Medium";
                                letter-spacing: -0.2px;
                                font-size: 12px;
                                line-height: 120%;
                                span{
                                    margin-right: 1.8px;
                                }
                            }
                        }
                    }
                }
            }
    
            // ======Testimonial======
            .herotestimonial-wrapper {
                margin-right: 2rem;
                margin-bottom: 4rem;
                .herotestimonial-container {
                    border-radius: 16px;
                    padding: 3.4rem 3rem;
                    width: 320px;
                    height: auto;
                    .herotestimonial-box {
                        display: grid;
                        grid-template-columns: none;
                        grid-template-rows: none;
                        .image-box {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            width: 100%;
                            height: auto;
                            border-radius: 16px;
                            video {
                                width: 224px;
                                height: 224px;
                            }
                            img {
                                width: 224px;
                                height: 224px;
                            }
                        }
                        .herotestimonial-description {
                            margin-top: 2.4rem;
                            .herotestimonial {
                                span {
                                    display: block;
                                    line-height: 1.5;
                                    letter-spacing: -0.2px;
                                    font-size: 12px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 5; /* number of lines to show */
                                    line-clamp: 5;
                                    -webkit-box-orient: vertical;
                                }
                            }
                            .test-info {
                                .testimonial-name {
                                    .name {
                                        letter-spacing: -0.2px;
                                        font-size: 13px;
                                    }
                                    .company-name {
                                        letter-spacing: -0.2px;
                                        font-size: 11px;
                                    }
                                }
                                svg {
                                    height: auto;
                                    width: 1.2px;
                                    margin: 0 12px;
                                }
                                img {
                                    width: 24px;
                                }
                            }
                        }
                    }
                }
            }
    
            // ======Contact======
            .contacthero-box {
                padding: 0 18px;
                margin-bottom: 4rem;
                .contacthero-container {
                    .contacthero-top {
                        padding-bottom: 16rem;
                        .contact-magnet {
                            display: inline-block;
                            position: absolute;
                            left: calc(100% - 260px); 
                            top: calc(100% - 292px);
                            z-index: 3;
                            a {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .hero-testimonial-heading {
        transform: translateY(16rem);
        margin-left: 18px;
        font-size: 3.8rem;
        letter-spacing: -3.6px;
        line-height: 90%;   
    }
    
    .click-to-play {
        color: #fff;
        top: 32.5em;
        left: 7.5em;
        font-size: 0.5rem;
        z-index: 2;
        opacity: 0.8;
        letter-spacing: -0.4px;
    }
}

@media screen and (max-width: 340px) {
    
    .hero-wrapper {
        .hero-container {
    
            // ======HERO======
            .main-hero {
                padding: 0 16px;
                .main-hero-container {
                    padding: 0 0.4rem;
                }
                .cta-text-box {
                    .cta-text {
                        padding: 4px 16px;
                        span {
                            font-size: 10px;
                            letter-spacing: -0.2px;
                        }
                    }
                }
                .heading-box {
                    span {
                        font-size: 28px;
                        letter-spacing: -1.8px;
                        line-height: 120%;
                    }
                }
                .subheading-box {
                    padding: 0 0.4rem;
                    span {
                        font-size: 12px;
                        letter-spacing: -0.4px;
                        line-height: 126%;
                    }
                }
                .cta-button-box {
                    margin-top: 0.2rem;
                    a {
                        button {
                            font-size: 9px;
                            letter-spacing: -0.1px;
                            padding: 8px 20px;
                            border-radius: 28px;
                        }
                    }
                }
            }
            .main-hero-element:not(:last-child) {
                margin-bottom: 0.8rem;
            }
    
            // ======Three Cards======
            .ThreeCards-container {
                padding: 0 28px;
                .cards-container {
                    .card {
                        border-radius: 8px;
                        width: 286px;
                        height: 300px;
                        margin-bottom: 1.5rem;
                        .top-card {
                            padding: 18px 20px;
                            .category {
                                span {
                                    font-size: 12px;
                                    text-transform: uppercase;
                                    font-family: "Satoshi-Medium";
                                    letter-spacing: -0.2px;
                                }
                            }
                            .card-heading {
                                span {
                                    font-size: 24px;
                                    font-family: "GeneralSans-Semibold";
                                    letter-spacing: -0.4px;
                                    line-height: 120%;
                                }
                            }
                            .card-subheading {
                                margin-top: 0.24rem;
                                line-height: 106%; /* 14.84px */
                                span {
                                    font-size: 12px;
                                    font-family: "GeneralSans";
                                    letter-spacing: -0.2px;
                                    font-style: normal;
                                    line-height: 100%;
                                }
                            }
                        }
                    }
                    .card3 {
                        grid-column: 1 / span 2; /* Span the entire width of the grid */
                        justify-self: center;
                    }
                    .card:not(:last-child) {
                        margin-right: 0rem;
                    }
                }
            }
    
            // ======Vision======
            .vision-container {
                padding: 0 18px;
                margin-bottom: 0rem;
                .vision-box {
                    padding-top: 6rem;
                    padding-bottom: 4rem;
                    display: block;
                    .vision-heading {
                        margin-bottom: 1rem;
                        .subheading {
                            margin-bottom: 0rem;
                            p {
                                letter-spacing: -0.4px;
                                font-size: 0.6rem;
                            }
                        }
                        .heading {
                            span {
                                font-size: 3.4rem;
                                letter-spacing: -3.6px;
                                line-height: 90%;
                            }
                        }
                    }
                    .vision-description {
                        padding: 1.6rem 0rem 1rem 5rem;
                        p {
                            margin: 0;
                            &:nth-of-type(1){
                                font-family: "GeneralSans-Medium";
                                letter-spacing: -0.2px;
                                font-size: 11px;
                                line-height: 120%;
                                span{
                                    margin-right: 1.8px;
                                }
                            }
                        }
                    }
                }
            }
    
            // ======Testimonial======
            .herotestimonial-wrapper {
                margin-top: 4rem;
                margin-right: 2rem;
                margin-bottom: 4rem;
                .herotestimonial-container {
                    border-radius: 16px;
                    padding: 3rem;
                    width: 290px;
                    height: auto;
                    .herotestimonial-box {
                        display: grid;
                        grid-template-columns: none;
                        grid-template-rows: none;
                        .image-box {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            width: 100%;
                            height: auto;
                            border-radius: 16px;
                            video {
                                width: 194px;
                                height: 194px;
                            }
                            img {
                                width: 194px;
                                height: 194px;
                            }
                        }
                        .herotestimonial-description {
                            margin-top: 1rem;
                            .herotestimonial {
                                span {
                                    display: block;
                                    line-height: 1.5;
                                    letter-spacing: -0.2px;
                                    font-size: 12px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 5; /* number of lines to show */
                                    line-clamp: 5;
                                    -webkit-box-orient: vertical;
                                }
                            }
                            .test-info {
                                .testimonial-name {
                                    .name {
                                        letter-spacing: -0.2px;
                                        font-size: 12px;
                                    }
                                    .company-name {
                                        letter-spacing: -0.2px;
                                        font-size: 10px;
                                    }
                                }
                                svg {
                                    height: auto;
                                    width: 1.2px;
                                    margin: 0 12px;
                                }
                                img {
                                    width: 24px;
                                }
                            }
                        }
                    }
                }
            }
    
            // ======Contact======
            .contacthero-box {
                padding: 0 18px;
                margin-bottom: 4rem;
                .contacthero-container {
                    .contacthero-top {
                        padding-bottom: 16rem;
                        .contact-magnet {
                            display: inline-block;
                            position: absolute;
                            left: calc(100% - 260px); 
                            top: calc(100% - 302px);
                            z-index: 3;
                            a {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .hero-testimonial-heading {
        transform: translateY(16rem);
        margin-left: 18px;
        font-size: 3.4rem;
        letter-spacing: -3.6px;
        line-height: 90%;   
    }
    
    .click-to-play {
        color: #fff;
        top: 28em;
        left: 7em;
        font-size: 0.5rem;
        z-index: 2;
        opacity: 0.8;
        letter-spacing: -0.4px;
    }
}

@media screen and (max-width: 280px) {
    
    .hero-wrapper {
        .hero-container {
    
            // ======HERO======
            .main-hero {
                padding: 0 16px;
                .main-hero-container {
                    padding: 0 0.4rem;
                }
                .cta-text-box {
                    .cta-text {
                        padding: 3px 12px;
                        span {
                            font-size: 9px;
                            letter-spacing: -0.2px;
                        }
                    }
                }
                .heading-box {
                    span {
                        font-size: 26px;
                        letter-spacing: -1.8px;
                        line-height: 120%;
                    }
                }
                .subheading-box {
                    padding: 0;
                    span {
                        font-size: 9px;
                        letter-spacing: -0.4px;
                        line-height: 126%;
                    }
                }
                .cta-button-box {
                    margin-top: 0.2rem;
                    a {
                        button {
                            font-size: 8px;
                            letter-spacing: -0.1px;
                            padding: 8px 20px;
                            border-radius: 28px;
                        }
                    }
                }
            }
            .main-hero-element:not(:last-child) {
                margin-bottom: 0.6rem;
            }
    
            // ======Three Cards======
            .ThreeCards-container {
                padding: 0 28px;
                .cards-container {
                    .card {
                        border-radius: 8px;
                        width: 240px;
                        height: 272px;
                        margin-bottom: 1.5rem;
                        .top-card {
                            padding: 12px 14px;
                            .category {
                                span {
                                    font-size: 10px;
                                    text-transform: uppercase;
                                    font-family: "Satoshi-Medium";
                                    letter-spacing: -0.2px;
                                }
                            }
                            .card-heading {
                                span {
                                    font-size: 20px;
                                    font-family: "GeneralSans-Semibold";
                                    letter-spacing: -0.4px;
                                    line-height: 120%;
                                }
                            }
                            .card-subheading {
                                margin-top: 0.24rem;
                                line-height: 106%; /* 14.84px */
                                span {
                                    font-size: 10px;
                                    font-family: "GeneralSans";
                                    letter-spacing: -0.2px;
                                    font-style: normal;
                                    line-height: 90%;
                                }
                            }
                        }
                    }
                    .card3 {
                        grid-column: 1 / span 2; /* Span the entire width of the grid */
                        justify-self: center;
                    }
                    .card:not(:last-child) {
                        margin-right: 0rem;
                    }
                }
            }
    
            // ======Vision======
            .vision-container {
                padding: 0 18px;
                margin-bottom: 0rem;
                .vision-box {
                    padding-top: 6rem;
                    padding-bottom: 4rem;
                    display: block;
                    .vision-heading {
                        margin-bottom: 1rem;
                        .subheading {
                            margin-bottom: 0.4rem;
                            p {
                                letter-spacing: -0.4px;
                                font-size: 0.6rem;
                            }
                        }
                        .heading {
                            span {
                                font-size: 3rem;
                                letter-spacing: -3.6px;
                                line-height: 90%;
                            }
                        }
                    }
                    .vision-description {
                        padding: 1.6rem 0rem 1rem 4rem;
                        p {
                            margin: 0;
                            &:nth-of-type(1){
                                font-family: "GeneralSans-Medium";
                                letter-spacing: -0.2px;
                                font-size: 10px;
                                line-height: 120%;
                                span{
                                    margin-right: 1.6px;
                                }
                            }
                        }
                    }
                }
            }
    
            // ======Testimonial======
            .herotestimonial-wrapper {
                margin-top: 0;
                margin-right: 2rem;
                margin-bottom: 2rem;
                .herotestimonial-container {
                    border-radius: 16px;
                    padding: 3rem;
                    width: 290px;
                    height: auto;
                    .herotestimonial-box {
                        display: grid;
                        grid-template-columns: none;
                        grid-template-rows: none;
                        .image-box {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            width: 100%;
                            height: auto;
                            border-radius: 16px;
                            video {
                                width: 194px;
                                height: 194px;
                            }
                            img {
                                width: 194px;
                                height: 194px;
                            }
                        }
                        .herotestimonial-description {
                            margin-top: 1rem;
                            .herotestimonial {
                                span {
                                    display: block;
                                    line-height: 1.5;
                                    letter-spacing: -0.2px;
                                    font-size: 12px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 5; /* number of lines to show */
                                    line-clamp: 5;
                                    -webkit-box-orient: vertical;
                                }
                            }
                            .test-info {
                                .testimonial-name {
                                    .name {
                                        letter-spacing: -0.2px;
                                        font-size: 12px;
                                    }
                                    .company-name {
                                        letter-spacing: -0.2px;
                                        font-size: 10px;
                                    }
                                }
                                svg {
                                    height: auto;
                                    width: 1.2px;
                                    margin: 0 12px;
                                }
                                img {
                                    width: 24px;
                                }
                            }
                        }
                    }
                }
            }
    
            // ======Contact======
            .contacthero-box {
                padding: 0 18px;
                margin-bottom: 4rem;
                .contacthero-container {
                    .contacthero-top {
                        padding-bottom: 16rem;
                        .heading {
                            .sub-sub-heading {
                                span {
                                    font-size: 14px;
                                    letter-spacing: -0.6px;
                                }
                            }
                            .sub-heading {
                                p {
                                    margin: 0;
                                    &:nth-of-type(1){
                                        color: var(--primary-100);
                                        font-size: 52px;
                                        font-family: "GeneralSans-Semibold";
                                        letter-spacing: -2px;
                                        line-height: 100%;
                                        span{
                                            margin-right: 6px;
                                        }
                                        .mask{
                                            position: relative;
                                            overflow: hidden;
                                            display: inline-flex;
                                        }
                                    }
                                }
                                span {
                                    
                                }
                            }
                        }
                        .contact-magnet {
                            display: inline-block;
                            position: absolute;
                            left: calc(100% - 252px); 
                            top: calc(100% - 292px);
                            z-index: 3;
                            a {
                                text-decoration: none;
                            }
                        }
                    }
                    .contacthero-bottom {
                        .contacthero-info {
                            margin-top: 1em;
                            .email {
                                span {
                                    font-size: 0.5rem;
                                    letter-spacing: -0.4px;
                                    font-family: "GeneralSans-Medium";
                                }
                            }
                            .email:not(:last-child) {
                                margin-right: 0.4em;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .hero-testimonial-heading {
        transform: translateY(16rem);
        margin-left: 18px;
        font-size: 3rem;
        letter-spacing: -3.6px;
        line-height: 90%;   
    }
    
    .click-to-play {
        color: #fff;
        top: 28em;
        left: 7em;
        font-size: 0.5rem;
        z-index: 2;
        opacity: 0.8;
        letter-spacing: -0.4px;
    }
}
.header-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    padding: 0 96px;
    .header-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .logo-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            a {
                text-decoration: none;
                img {
                    width: 32px;
                    transform: translateY(-2px);
                    -ms-transform: translateY(-2px);
                    -webkit-transform: translateY(-2px);
                    -moz-transform: translateY(-2px);
                    -o-transform: translateY(-2px);
                }
            }
        }
        .header-navigations {
            border-radius: 8px;
            border: 1px solid rgba(53, 54, 52, 0.18);
            background: rgba(53, 54, 52, 0.12);
            backdrop-filter: blur(18px);
            align-items: center;
            .navigations-link {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 8px 32px;
                width: 100%;
                a {
                    text-decoration: none;
                    font-size: 14px;
                    font-family: "Satoshi-Medium";
                    letter-spacing: -0.1px;  
                }
                a:not(:last-child) {
                    margin-right: 42px;
                }
            }
        }
        .cta-icon {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
                width: 30px;
            }
        }
        .hamburger-menu {
            display: none;
        }
    }
}

@media screen and (max-width: 1189px) {
    .header-wrapper {
        margin-top: 20px;
        padding: 0 80px;
    }
}

@media screen and (max-width: 1024px) {
    .header-wrapper {
        margin-top: 20px;
        padding: 0 64px;
        .header-container {
            .logo-box {
                a {
                    img {
                        width: 24px;
                    }
                }
            }
            .header-navigations {
                border-radius: 6px;
                .navigations-link {
                    padding: 8px 28px;
                    a {
                        font-size: 13px;
                    }
                    a:not(:last-child) {
                        margin-right: 40px;
                    }
                }
            }
            .cta-icon {
                img {
                    width: 26px;
                }
            }
        }
    }
}

@media screen and (max-width: 825px) {
.header-wrapper {
        margin-top: 24px;
        padding: 0 56px;
        .header-container {
            .logo-box {
                a {
                    img {
                        width: 24px;
                    }
                }
            }
            .header-navigations {
                border-radius: 6px;
                .navigations-link {
                    padding: 8px 28px;
                    a {
                        font-size: 13px;
                    }
                    a:not(:last-child) {
                        margin-right: 40px;
                    }
                }
            }
            .cta-icon {
                img {
                    width: 26px;
                }
            }
        }
    }
}

@media screen and (max-width: 769px) {
    .header-wrapper {
        margin-top: 24px;
        padding: 0 48px;
        .header-container {
            .logo-box {
                a {
                    img {
                        width: 22px;
                    }
                }
            }
            .header-navigations {
                border-radius: 6px;
                .navigations-link {
                    padding: 8px 24px;
                    a {
                        font-size: 12px;
                    }
                    a:not(:last-child) {
                        margin-right: 28px;
                    }
                }
            }
            .cta-icon {
                img {
                    width: 24px;
                }
            }
        }
    }
}

@media screen and (max-width: 440px) {
    .header-wrapper {
        margin-top: 24px;
        padding: 0 32px;
        .header-container {
            .logo-box {
                a {
                    img {
                        width: 27px;
                        transform: translateY(0px);
                        -ms-transform: translateY(0px);
                        -webkit-transform: translateY(0px);
                        -moz-transform: translateY(-0x);
                        -o-transform: translateY(0px);
                    }
                }
            }
            .header-navigations {
                display: none;
            }
            .cta-icon {
                display: none;
            }
            .hamburger-menu {
                display: block;
            }
        }
    }
}
.tooltip-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    .tooltip {
        transform: translateX(-50%);
        width: max-content;
        inline-size: max-content;
        height: max-content;
        block-size: max-content;
        top: 120%;
        position: absolute;
        background: #0d0603;
        color: #f8eee5;
        border: 0.9px solid rgba(111, 109, 109, 0.3);
        padding: 6px 10px;
        border-radius: 5px;
        z-index: 1;
        font-size: 0.75rem;
        font-family: "Satoshi-Medium";
        text-align: center;
    }
}
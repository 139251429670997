.aboutus-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .aboutus-container {
        width: 100%;
        height: 100%;
        .mainaboutus-container {
            width: 100%;
            padding: 0 96px;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: hidden;

            .aboutus-heading {
                width: 100%;
                padding-top: 4rem;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                color: #0d0603;
                margin-bottom: 2rem;
                .heading {
                    margin-bottom: 1rem;
                    width: 100%;
                    font-size: 3.8rem;
                    font-family: "GeneralSans-Medium";
                    letter-spacing: -3px;
                    text-align: left;
                }
                .sub-heading {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    justify-content: space-between;
                    a {
                        text-decoration: none;
                        .button-talktous {
                            display: inline-flex;
                            flex-direction: row;
                            align-items: center;
                            font-size: 1rem;
                            font-family: "GeneralSans-Medium";
                            padding: 4px 14px;
                            border: 1px solid #0d0603;
                            color: #0d0603;
                            border-radius: 0 8px 0 0;
                            transition: 0.7s ease-in-out;
                            img {
                                margin-left: 0.4rem;
                                width: 10px;
                            }
                            &:hover {
                                border-radius: 8px;
                            }
                        }
                    }
                    .text {
                        width: 30%;
                        font-size: 0.9rem;
                        font-family: "GeneralSans-Medium";
                        letter-spacing: -0.4px;
                        line-height: 22px;
                        text-align: right;
                    }
                }
            }

            .mainvideo-box {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                .video-box {
                    border: 1.2px solid #0d0603;
                    border-radius: 12px;
                    width: 100%;
                    overflow: hidden;
                    img {
                        width: 100%;
                    }
                }
            }

            .countdown {
                margin: 8rem 0;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                .projectscmpltd, .clientssatisfaction, .years {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    p {
                        font-size: 2rem;
                        font-family: "GeneralSans-Medium";
                        letter-spacing: -1px;
                    }
                    span {
                        text-align: center;
                        font-family: "GeneralSans";
                        font-size: 0.9rem;
                    }
                    &:not(:last-child) {
                        border-right: 1.6px solid #0d0603;
                    }
                }
                .projectscmpltd {
                    padding-right: 2.8rem;
                }
                .clientssatisfaction {
                    padding: 0 2.8rem;
                }
                .years {
                    padding-left: 2.8rem;
                }
            }

            .privacy-policy-link {
                width: 100%;
                text-align: center;
                padding: 20px 0;
                margin: 20px 0;
                border-top: 1px solid #0d0603;
                
                a {
                    color: #0d0603;
                    text-decoration: none;
                    font-size: 0.9rem;
                    font-family: "GeneralSans-Medium";
                    transition: color 0.3s ease;
                    
                    &:hover {
                        color: #333333;
                    }
                }
            }

            .ribbon {
                margin-bottom: 2rem;
            }
        }
    }
}

@media screen and (max-width: 1189px) {
    .aboutus-wrapper {
        width: 100%;
        .aboutus-container {
            width: 100%;
            height: 100%;
            .mainaboutus-container {
                width: 100%;
                padding: 0 80px;
                
                .aboutus-heading {
                    padding-top: 5rem;
                    .sub-heading {
                        .text {
                            width: 36%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .aboutus-wrapper {
        width: 100%;
        .aboutus-container {
            width: 100%;
            height: 100%;
            .mainaboutus-container {
                padding: 0 64px;
                .aboutus-heading {
                    padding-top: 4rem;
                    .heading {
                        font-size: 3.4rem;
                    }
                    .sub-heading {
                        a {
                            .button-talktous {
                                font-size: 0.9rem;
                            }
                        }
                        .text {
                            font-size: 0.8rem;
                            line-height: 20px;
                        }
                    }
                }

                .countdown {
                    margin: 6rem 0;
                    .projectscmpltd, .clientssatisfaction, .years {
                        p {
                            font-size: 1.6rem;
                        }
                        span {
                            font-size: 0.8rem;
                        }
                    }
                }

                .privacy-policy-link {
                    a {
                        font-size: 0.85rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 825px) {
    .aboutus-wrapper {
        width: 100%;
        .aboutus-container {
            width: 100%;
            height: 100%;
            .mainaboutus-container {
                padding: 0 56px;
            }
            .ribbon {
                margin-bottom: 1.6rem;
            }
        }
    }
}

@media screen and (max-width: 769px) {
    .aboutus-wrapper {
        width: 100%;
        .aboutus-container {
            width: 100%;
            height: 100%;
            .mainaboutus-container {
                padding: 0 48px;
                
                .aboutus-heading {
                    padding-top: 3rem;
                    .heading {
                        font-size: 3rem;
                        letter-spacing: -2.6px;
                    }
                    .sub-heading {
                        a {
                            .button-talktous {
                                font-size: 0.85rem;
                            }
                        }
                        .text {
                            width: 50%;
                            font-size: 0.72rem;
                        }
                    }
                }

                .privacy-policy-link {
                    a {
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 440px) {
    .aboutus-wrapper {
        width: 100%;
        .aboutus-container {
            width: 100%;
            height: 100%;
            .mainaboutus-container {
                padding: 0 32px;
                
                .aboutus-heading {
                    padding-top: 2rem;
                    .heading {
                        font-size: 2.6rem;
                        letter-spacing: -2px;
                    }
                    .sub-heading {
                        display: flex;
                        flex-direction: row-reverse;
                        a {
                            .button-talktous {
                                border-radius: 6px;
                                font-size: 0.8rem;
                                padding: 3px 10px;
                                img {
                                    width: 9px;
                                    margin-left: 0.3rem;
                                }
                            }
                        }
                        .text {
                            width: 64%;
                            text-align: left;
                            font-size: 0.7rem;
                            letter-spacing: -0.4px;
                            line-height: 18px;
                        }
                    }
                }

                .countdown {
                    margin: 3rem 0;
                    .projectscmpltd, .clientssatisfaction, .years {
                        p {
                            font-size: 1.3rem;
                        }
                        span {
                            font-size: 0.7rem;
                            line-height: 14px;
                        }
                        &:not(:last-child) {
                            border-right: 1px solid #0d0603;
                        }
                    }
                    .projectscmpltd {
                        padding-right: 1.4rem;
                    }
                    .clientssatisfaction {
                        padding: 0 1.4rem;
                    }
                    .years {
                        padding-left: 1.4rem;
                    }
                }

                .privacy-policy-link {
                    padding: 15px 0;
                    a {
                        font-size: 0.75rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 414px) {
    .aboutus-wrapper {
        width: 100%;
        .aboutus-container {
            width: 100%;
            height: 100%;
            .mainaboutus-container {
                padding: 0 26px;
            }
        }
    }
}

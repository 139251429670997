@tailwind base;
@tailwind components;
@tailwind utilities;

/* =================TYPOGRAPHY================= */

/* -----General Sans----- */
@font-face {
  font-family: "GeneralSans";
  src: url("../src/assets/fonts/GeneralSans-Regular.ttf");
}
@font-face {
  font-family: "GeneralSans-Medium";
  src: url("../src/assets/fonts/GeneralSans-Medium.ttf");
}
@font-face {
  font-family: "GeneralSans-MediumItalic";
  src: url("../src/assets/fonts/GeneralSans-MediumItalic.ttf");
}
@font-face {
  font-family: "GeneralSans-Semibold";
  src: url("../src/assets/fonts/GeneralSans-Semibold.ttf");
}
@font-face {
  font-family: "GeneralSans-Bold";
  src: url("../src/assets/fonts/GeneralSans-Bold.ttf");
}

/* -----Satoshi----- */
@font-face {
  font-family: "Satoshi";
  src: url("../src/assets/fonts/Satoshi-Regular.ttf");
}
@font-face {
  font-family: "Satoshi-Italic";
  src: url("../src/assets/fonts/Satoshi-Italic.ttf");
}
@font-face {
  font-family: "Satoshi-Medium";
  src: url("../src/assets/fonts/Satoshi-Medium.ttf");
}
@font-face {
  font-family: "Satoshi-MediumItalic";
  src: url("../src/assets/fonts/Satoshi-MediumItalic.ttf");
}
@font-face {
  font-family: "Satoshi-Bold";
  src: url("../src/assets/fonts/Satoshi-Bold.ttf");
}
@font-face {
  font-family: "Satoshi-BoldItalic";
  src: url("../src/assets/fonts/Satoshi-BoldItalic.ttf");
}

/* =================COLORS================= */
:root {
  --primary-400: #0C0D0E;
  --primary-300: #101214;
  --primary-200: #1A1A1A;
  --primary-100: #0d0603;
  --secondary-100: #F7F7F7;
  --secondary-200: #d9dbd7;
  --secondary-300: #747573;
  --accent-100: #f8eee5;
}


/* =================*================= */
*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


/* =================Body & Scroll Bar Hiding================= */
body {
  background: #f8eee5;
}
body::-webkit-scrollbar {
  display: none;
}

/* =================Section================= */
section {
  height: 100dvh;
}

/* =================Overall App================= */
.app {
  user-select: none; 
  -moz-user-select: none;
  -webkit-text-select: none;
  -webkit-user-select: none;
}
.contact-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .contact-container {
        width: 100%;
        height: 100%;
        
        .maincontact-container {
            width: 100%;
            padding: 0 96px;
            margin-bottom: 6rem;
            margin-top: 5rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .contact-heading {
                display: flex;
                flex-direction: column-reverse;
                align-items: flex-start;
                .heading {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    p {
                        opacity: 80%;
                        font-family: "GeneralSans-Medium";
                        font-size: 2rem;
                        color: #0d0603;
                        line-height: 42px;
                        letter-spacing: -1.4px;
                        span {
                            font-size: 2.4rem;
                            font-family: "GeneralSans-Semibold";
                            color: #404040;
                        }
                    }
                }
                .sub-heading {
                    letter-spacing: -2px;
                    font-size: 3rem;
                    font-family: "GeneralSans-Semibold";
                    color: #404040;
                }
            }

            .form-and-socials {
                margin-top: 4rem;
                display: grid;
                grid-template-columns: 2fr 1fr;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;
                .left-form {
                    width: 100%;
                    form {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;   
                        width: 100%;
                        .formlabel {
                            width: 100%;
                            margin-bottom: 2rem;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            label {
                                font-family: "GeneralSans-Medium";
                                font-size: 1.2rem;
                                color: #0d0603;
                                margin-bottom: 6px;
                                letter-spacing: -0.4px;
                                span {
                                    color: #b81200;
                                }
                            }
                            input {
                                width: 64%;
                                color: #0d0603;
                                border: 1px solid #0d0603;
                                padding: 6px 12px;
                                border-radius: 8px;
                                text-decoration: none;
                                outline: none;
                                font-family: "GeneralSans-Medium";
                                letter-spacing: -0.2px;
                            }
                            textarea {
                                width: 64%;
                                height: 180px;
                                color: #0d0603;
                                border: 1px solid #0d0603;
                                padding: 6px 12px;
                                border-radius: 8px;
                                text-decoration: none;
                                outline: none;
                                font-family: "GeneralSans-Medium";
                                letter-spacing: -0.2px;
                            }
                        }
                        .submit-button {
                            font-family: 1.2rem;
                            font-family: "Satoshi-Bold";
                        }
                    }
                }

                .right-details {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;
                    .furtherenquiry {
                        a {
                            text-decoration: none;
                            .button-talktous {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                font-size: 0.8rem;
                                font-family: "GeneralSans-Medium";
                                letter-spacing: -0.2px;
                                padding: 3px 12px;
                                border: 1px solid #0d0603;
                                border-radius: 4px 8px 8px 4px;
                                img {
                                    width: 10px;
                                    margin-left: 0.4rem;
                                }
                            }
                        }
                    }

                    .collaboration, .furtherenquiry, .socials, .location {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: 4rem;
                        .enq-heading, .collab-heading, .socials-heading, .location-heading {
                            width: 100%;
                            color: #404040;
                            font-size: 1.1rem;
                            font-family: "GeneralSans-Semibold";
                            letter-spacing: -0.2px;
                            margin-bottom: 0.8rem;
                        }
                    }
                }
            }
        }
    }
}

.a-email, .a-whatsapp, .a-ig, .a-fb, .a-in, .time, .location-detail {
    color: #4e4e4e;
    text-decoration: none;
    font-family: "GeneralSans-Medium";
    letter-spacing: -0.2px;
}

@media screen and (max-width: 1189px) {
    .contact-wrapper {
        width: 100%;
        .contact-container {
            width: 100%;
            height: 100%;

            .maincontact-container {
                padding: 0 80px;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .contact-wrapper {
        width: 100%;
        .contact-container {
            width: 100%;
            height: 100%;

            .maincontact-container {
                padding: 0 64px;
            }
        }
    }
}

@media screen and (max-width: 825px) {
    .contact-wrapper {
        width: 100%;
        .contact-container {
            width: 100%;
            height: 100%;

            .maincontact-container {
                padding: 0 56px;
                margin-bottom: 5rem;
                margin-top: 4rem;
                .contact-heading {
                    .heading {
                        p {
                            font-size: 1.6rem;
                            letter-spacing: -1px;
                            span {
                                font-size: 2rem;
                            }
                        }
                    }
                    .sub-heading {
                        font-size: 2.4rem;
                    }
                }

                .form-and-socials {
                    margin-top: 3rem;
                    .left-form {
                        form {
                            .formlabel {
                                margin-bottom: 1.8rem;
                                label {
                                    font-size: 1rem;
                                    letter-spacing: -0.2px;
                                }
                                input {
                                    width: 76%;
                                    font-size: 0.9rem;
                                    border: 0.8px solid #0d0603;
                                    padding: 5px 10px;
                                    border-radius: 6px;
                                }
                                textarea {
                                    width: 76%;
                                    font-size: 0.9rem;
                                    border: 0.8px solid #0d0603;
                                    padding: 5px 10px;
                                    border-radius: 6px;
                                    height: 140px;
                                }
                            }
                            button {
                                font-size: 0.9rem;
                            }
                        }
                    }

                    .right-details {
                        .furtherenquiry {
                            a {
                                .button-talktous {
                                    padding: 2px 10px;
                                }
                            }
                        }

                        .collaboration, .furtherenquiry, .socials, .location {
                            margin-bottom: 3rem;
                            .enq-heading, .collab-heading, .socials-heading, .location-heading {
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .a-email, .a-fb, .a-ig, .a-in, .a-whatsapp, .time, .location-detail {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 769px) {
    .contact-wrapper {
        width: 100%;
        .contact-container {
            width: 100%;
            height: 100%;
            
            .maincontact-container {
                padding: 0 48px;
                margin-bottom: 5rem;
                margin-top: 3.8rem;
            }
        }
    }
}

@media screen and (max-width: 440px) {
    .contact-wrapper {
        width: 100%;
        .contact-container {
            width: 100%;
            height: 100%;

            .maincontact-container {
                padding: 0 32px;
                margin-bottom: 5rem;
                margin-top: 3rem;
                .contact-heading {
                    .heading {
                        p {
                            font-size: 1.2rem;
                            letter-spacing: -1px;
                            line-height: 32px;
                            span {
                                font-size: 1.3rem;
                            }
                        }
                    }
                    .sub-heading {
                        font-size: 2rem;
                    }
                }

                .form-and-socials {
                    margin-top: 3rem;
                    display: flex;
                    flex-direction: column-reverse;
                    .left-form {
                        form {
                            .formlabel {
                                margin-bottom: 1.8rem;
                                label {
                                    font-size: 1rem;
                                    letter-spacing: -0.2px;
                                }
                                input {
                                    width: 100%;
                                    font-size: 0.9rem;
                                    border: 0.8px solid #0d0603;
                                    padding: 5px 10px;
                                    border-radius: 6px;
                                }
                                textarea {
                                    width: 100%;
                                    font-size: 0.9rem;
                                    border: 0.8px solid #0d0603;
                                    padding: 5px 10px;
                                    border-radius: 6px;
                                    height: 140px;
                                }
                            }
                            button {
                                font-size: 0.9rem;
                            }
                        }
                    }

                    .right-details {
                        .furtherenquiry {
                            a {
                                .button-talktous {
                                    padding: 2px 10px;
                                    margin-bottom: 4px;
                                }
                            }
                        }

                        .socials, .location {
                            display: none;
                        }

                        .collaboration, .furtherenquiry, .socials, .location {
                            margin-bottom: 3rem;
                            .enq-heading, .collab-heading, .socials-heading, .location-heading {
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .a-email, .a-fb, .a-ig, .a-in, .a-whatsapp, .time, .location-detail {
        font-size: 0.9rem;
    }
}
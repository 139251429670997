.roundedButton{
    border-radius: 2.4em;
    border: 1px solid rgb(136, 136, 136);
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 40px 12px 40px;

    span{
        position: relative;
        z-index: 1;
        transition: color 0.4s linear;
    }

    &:hover{
        span{
            color: #f8eee5;
        }
    }
}

.ContactRoundedButton{
    border-radius: 2.4em;
    border: 1px solid #0d0603;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 40px 12px 40px;

    button{
        position: relative;
        z-index: 1;
        transition: color 0.4s linear;
    }

    &:hover{
        button{
            color: #f8eee5;
        }
    }
}

.circle{
    width: 100%;
    height: 150%;
    position: absolute;
    border-radius: 50%;
    top: 100%;
}

@media screen and (max-width: 769px) {
    .roundedButton {
        padding: 10px 32px 10px 32px;
    }
    .ContactRoundedButton {
        padding: 10px 32px 10px 32px;
    }
}
@media screen and (max-width: 440px) {
    .roundedButton {
        padding: 9px 24px;
    }
    .ContactRoundedButton {
        padding: 9px 24px;
    }
}
@media screen and (max-width: 340px) {
    .roundedButton {
        padding: 8px 20px;
    }
    .roundedBuContactRoundedButtontton {
        padding: 8px 20px;
    }
}
.blog-wrapper {
    position: relative;
    .blog-container {
        width: 100%;
        height: 100dvh;
        display: flex;
        flex-direction: column;

        .mainblog-container {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img {
                width: 140px;
                border-radius: 0.5rem;
                box-shadow: 0px 32px 64px -12px rgba(0, 0, 0, 0.14);
            }
        }
    }
}

@media screen and (max-width: 440px) {
    .blog-wrapper {
        .blog-container {
            .mainblog-container {
                img {
                    width: 80px;
                }
            }
        }
    }
}
.footer-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.72rem 0.6rem 0.4rem 0.6rem;
    background: linear-gradient(180deg, #f8eee5 28.17%, #FFF 84.61%);
    .footer-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .top-foot {
            display: grid;
            grid-template-columns: 2.5fr 2fr;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 2rem;
            .navigation-foot {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                a {
                    font-size: 20px;
                    font-family: "GeneralSans-Medium";
                    letter-spacing: -0.4px;
                    color: var(--primary-300);
                }
            }
            .talk-with-expert-foot {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                .talk-with-expert-foottop {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: space-between;
                    .talk-with-expert-text {
                        p {
                            font-size: 20px;
                            font-family: "GeneralSans-Medium";
                            letter-spacing: -0.4px;
                            line-height: 112%;
                        }
                    }
                    .socialmedia {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        a {
                            text-decoration: none;
                            img {
                                width: 24px;
                                color: var(--primary-300);
                            }
                        }
                        a:not(:last-child) {
                            margin-right: 2px;
                        }
                    }
                }
                .talk-with-expert-footbottom {
                    margin-top: 8px;
                    .contact-number {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: 12px;
                            margin-right: 6px;
                        }
                        span {
                            font-size: 14px;
                            font-family: "GeneralSans";
                            letter-spacing: -0.4px;
                        }
                    }
                }
            }
        }
        .bottom-foot {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            align-items: flex-end;
            .bottom-left-combo {
                .bottom-foot-left {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    span {
                        font-size: 12px;
                        font-family: "Satoshi";
                    }
                }
                .bottom-foot-middle {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    span {
                        font-size: 12px;
                        font-family: "Satoshi";
                        text-transform: capitalize;
                    }
                }
            }
            .bottom-foot-right {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
                .footer-logo {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    img {
                        width: 40px;
                        margin-right: 4px;
                    }
                    span {
                        font-size: 48px;
                        font-family: "GeneralSans-Semibold";
                        letter-spacing: -0.72px;
                        line-height: 96%;
                        color: var(--primary-100);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 825px) {
    .footer-wrapper {
        .footer-container {
            .bottom-foot {
                .bottom-foot-right {
                    .footer-logo {
                        img {
                            width: 28px;
                        }
                        span {
                            font-size: 38px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 440px) {
    .footer-wrapper {
        .footer-container {
            .top-foot {
                grid-template-columns: 2fr 2fr;
                margin-bottom: 2rem;
                .navigation-foot {
                    a {
                        font-size: 16px;
                        letter-spacing: -0.4px;
                    }
                }
                .talk-with-expert-foot {
                    .talk-with-expert-foottop {
                        .talk-with-expert-text {
                            p {
                                font-size: 16px;
                                letter-spacing: -0.4px;
                                line-height: 112%;
                            }
                        }
                        .socialmedia {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr;
                            a {
                                text-decoration: none;
                                img {
                                    width: 20px;
                                }
                            }
                            a:not(:last-child) {
                                margin-right: 1px;
                            }
                        }
                    }
                    .talk-with-expert-footbottom {
                        margin-top: 4px;
                        .contact-number {
                            img {
                                width: 10px;
                                margin-right: 4px;
                            }
                            span {
                                font-size: 12px;
                                letter-spacing: -0.4px;
                            }
                        }
                    }
                }
            }
            .bottom-foot {
                .bottom-left-combo {
                    .bottom-foot-left {
                        span {
                            font-size: 10px;
                        }
                    }
                    .bottom-foot-middle {
                        span {
                            font-size: 10px;
                        }
                    }
                }
                .bottom-foot-right {
                    .footer-logo {
                        img {
                            width: 28px;
                            margin-right: 8px;
                        }
                        span {
                            font-size: 38px;
                            letter-spacing: -0.72px;
                            line-height: 96%;
                        }
                    }
                }
            }
        }
    }   
}
.Hamburger-whole-screen {
    height: calc(100dvh - 32px) !important;
    width: calc(100% - 32px) !important;
    a {
        font-size: 2rem;
    }
}

@media screen and (max-width: 340px) {
    .Hamburger-whole-screen {
        .hamburger-whole-screen-logo {
            width: 4rem;
            height: 4rem;
            .smallhamburger-whole-screen-logo {
                width: 26px;
            }
        }
        .hamburger-whole-screen-nav {
            padding: 1rem;
        }
        .hamburger-whole-screen-nav-text {
            font-size: 2rem;
        }
        .hamburger-whole-screen-footer {
            font-size: 1.5rem;
        }
    }
}
